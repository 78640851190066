<template>
  <a-modal v-model="modalVisible" title="报警记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal">
    <div class="info-card" >
      <a-form-model ref="modalForm" :rules="formRules" layout="inline" class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
        <div class="card-content">
          <h3 style="font-weight: bold; color: #333;">投诉添加</h3>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;">
            <span style="margin-left: 2%">电梯信息</span>
          </h3>
          <a-form-model-item label="救援代码" prop="liftrescue">
            <a-input  v-model="queryForms.liftrescue" placeholder="" style="width:100%;margin-top: -100px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="电梯注册码" prop="liftnum">
            <a-input v-model="queryForms.liftnum" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="" prop="callertel">
            <a-button type="primary" @click="liftSelect">选择</a-button>
          </a-form-model-item>
          <a-form-model-item label="安装地址" prop="adress">
            <a-input v-model="queryForms.adress" placeholder=""></a-input>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">单位信息</span>
          </h3>
          <a-form-model-item label="使用单位" prop="userdepname">
            <a-input v-model="queryForms.userdepname" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="userdeppreson">
            <a-input  v-model="queryForms.userdeppreson" placeholder="" style="width:100%;margin-top: -100px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="userdeppersonphone">
            <a-input v-model="queryForms.userdeppersonphone" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保单位" prop="maintenancedepname">
            <a-input v-model="queryForms.maintenancedepname" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="maintenanceperson1">
            <a-input v-model="queryForms.maintenanceperson1" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="maintenancedephone">
            <a-input v-model="queryForms.maintenancedephone" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="救援单位" prop="pristation">
            <a-input v-model="queryForms.pristation" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="pristationperson">
            <a-input v-model="queryForms.pristationperson" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="pristationperphone">
            <a-input v-model="queryForms.pristationperphone" placeholder=""></a-input>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">投诉人信息</span>
          </h3>
          <a-form-model-item label="投诉人" prop="alarmcaller">
            <a-input  v-model="queryForms.alarmcaller" placeholder="请输入" style="width:100%;margin-top: -100px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="callertel">
            <a-input v-model="queryForms.callertel" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="处理状态" prop="status">
            <a-select id="status" placeholder="请选择" v-model="queryForms.status">
              <a-select-option value="">请选择</a-select-option>
              <a-select-option :value="0">已受理</a-select-option>
              <a-select-option :value="1">已完成</a-select-option>
            </a-select>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">接线人信息</span>
          </h3>
          <a-form-model-item label="接线人姓名" prop="username">
            <a-input v-model="queryForms.username" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">投诉信息</span>
          </h3>
          <a-form-model-item prop="message" style="width: 48%;">
            <textarea v-model="queryForms.message" style="width:300%;height:150px;margin-left: 35%" placeholder="投诉信息"></textarea>
          </a-form-model-item>
          <div class="showbutton" style="text-align: center">
            <a-button type="primary"  @click="save()">保存</a-button>
            <a-button @click="close">关闭</a-button>
          </div>
        </div>
      </a-form-model>
      <liftSelect :visible.sync="liftselectVisible" ref="liftselect" @upDate="change"></liftSelect>
    </div>
  </a-modal>
</template>

<script>
import moment from "moment";
import liftSelect from "V/yjcz/emergency/liftSelect";
import {addAlarmessage} from "A/thjlcx";
import {getLiftinfoById} from "A/jcgn";
import {getCache} from "U/index";

export default {
  name: "addComplaint",
  components:{
    liftSelect
  },
  props: {
    visible: {
      default: false
    },
  },
  data() {
    return {
      liftselectVisible:false,
      modalVisible:false,
      moment,
      pages: '',
      alarmtype:'',
      queryForms:{
        alarmtype:'',
        caller:'',
        called:'',
        alarmcaller:'',
        calltime:'',
        message:'',
        uesrname:'',
      },
      formRules: {
        liftrescue: [{required: true, message: '请选择电梯' ,trigger:'blur,change'}],
        calledtime: [{required: true, message: '请选择受理时间',trigger:'blur,change'}],
      },
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initData();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
    },
    liftSelect(){
      this.liftselectVisible=true;
    },
    initData(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.queryForms.username = logininfo.usernames;
      }
    },
    save(){
      if(this.queryForms.liftrescue==null||this.queryForms.liftrescue==""||this.queryForms.calledtime==null||this.queryForms.calledtime==""){
        this.$message.info("请填写完整信息")
      }else {
        let params = {
          liftrescue: this.queryForms.liftrescue,
          alarmtype: 2,
          caller: this.queryForms.alarmcaller,
          callertel: this.queryForms.callertel,
          called: this.username,
          calltime: this.queryForms.calledtime,
          message: this.queryForms.message,
          status: this.queryForms.status,
        }
        addAlarmessage(params)
        this.modalVisible = false
      }
    },
    change(msg){
      let params = {
        liftid:msg
      }
      getLiftinfoById(params).then(res=>{
        if(res && res.returncode=='0'){
          this.queryForms=res.item
          this.queryForms.maintenancedephone=res.item.userdepLists.maintenancephone1
          this.queryForms.maintenanceperson1=res.item.userdepLists.maintenanceperson1
        }
      })
    },
    close(){
      this.modalVisible = false
    },
  },
}
</script>

<style scoped>

</style>