<template>
  <a-modal v-model="modalVisible" title="报警记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal">
    <div class="info-card" >
      <a-form-model ref="modalForm" layout="inline" class="form-in-threecolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
        <div class="card-content">
          <h3 style="font-weight: bold; color: #333;">投诉添加</h3>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;">
            <span style="margin-left: 2%">电梯信息</span>
          </h3>
          <a-form-model-item label="救援代码" prop="liftrescue">
            <a-input  v-model="queryForms.liftrescue" placeholder="" style="width:100%;margin-top: -100px" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="电梯注册码" prop="liftnum">
            <a-input v-model="queryForms.liftnum" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
<!--          <a-form-model-item label="" prop="callertel">-->
<!--            <a-button type="primary" @click="liftSelect">选择</a-button>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="安装地址" prop="adress">
            <a-input v-model="queryForms.adress" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">单位信息</span>
          </h3>
          <a-form-model-item label="使用单位" prop="userdepname">
            <a-input v-model="queryForms.userdepname" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="userdeppreson">
            <a-input  v-model="queryForms.userdeppreson" placeholder="" style="width:100%;margin-top: -100px" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="userdeppersonphone">
            <a-input v-model="queryForms.userdeppersonphone" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保单位" prop="maintenancedepname">
            <a-input v-model="queryForms.maintenancedepname" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="maintenanceperson1">
            <a-input v-model="queryForms.maintenanceperson1" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="maintenancephone1">
            <a-input v-model="queryForms.maintenancephone1" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="救援单位" prop="pristation">
            <a-input v-model="queryForms.pristation" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="pristationperson">
            <a-input v-model="queryForms.pristationperson" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="pristationperphone">
            <a-input v-model="queryForms.pristationperphone" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">投诉人信息</span>
          </h3>
          <a-form-model-item label="投诉人" prop="alarmcaller">
            <a-input  v-model="queryForms.caller" placeholder="请输入" style="width:100%;margin-top: -100px" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="callertel">
            <a-input v-model="queryForms.callertel" placeholder="请输入" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="处理状态" prop="status">
            <a-select id="status" placeholder="请选择" v-model="queryForms.status" :disabled="true">
              <a-select-option value="">请选择</a-select-option>
              <a-select-option :value="0">已受理</a-select-option>
              <a-select-option :value="1">已完成</a-select-option>
            </a-select>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">接线人信息</span>
          </h3>
          <a-form-model-item label="接线人姓名" prop="username">
            <a-input v-model="queryForms.called" placeholder="" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="接线时间" prop="calledtime">
            <a-date-picker
                v-model="queryForms.calledtime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">投诉信息</span>
          </h3>
          <a-form-model-item prop="message" style="width: 48%;">
            <textarea v-model="queryForms.message" style="width:300%;height:150px;margin-left: 35%" placeholder="投诉信息" :disabled="true"></textarea>
          </a-form-model-item>
          <div class="showbutton" style="text-align: right">
            <a-button type="primary" @click="close">关闭</a-button>
          </div>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import moment from "moment";
import {getLiftinfoById, getLiftinfoListByCondition} from "A/jcgn";

export default {
  name: "complaintBack",
  props: {
    ComplaintData:{
      default:null
    },
    visible: {
      default: false
    },
  },
  data() {
    return {
      modalVisible:false,
      moment,
      pages: '',
      uesrname:'',
      alarmtype:'',
      queryForms:{
        liftrescue:'',
        liftnum:'',
        adress:'',
        caller:'',
        alarmtype:'',
        alarmcaller:'',
        called:'',
        calltime:'',
        message:'',
        userdepname:'',
        userdeppreson :'',
        userdeppersonphone:'',
        maintenancedepname :'',
        maintenanceperson1 :'',
        maintenancephone1 :'',
        pristation :'',
        rescuepreson :'',
        rescuephone:'',
        callertel:'',
        calledtime:'',
        status:'',
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.queryForms.liftrescue=this.ComplaintData.liftrescue
        this.queryForms.caller=this.ComplaintData.caller
        this.queryForms.callertel=this.ComplaintData.callertel
        this.queryForms.called=this.ComplaintData.called
        this.queryForms.message=this.ComplaintData.message
        this.queryForms.status=this.ComplaintData.status
        this.queryForms.calledtime=this.ComplaintData.calltime
        console.log(this.ComplaintData.calltime)
        this.getLift();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    onChange (value, dateString) {
      this.queryForms.calledtime=dateString
    },
    cancelModal(e) {
      this.modalVisible = false
    },
    getLift(){
      let params = {
        liftrescue: this.ComplaintData.liftrescue
      }
      getLiftinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          let params = {
            liftid:res.item[0].liftid
          }
          getLiftinfoById(params).then(res => {
            console.log(res.item)
            this.queryForms.liftnum=res.item.liftnum
            this.queryForms.adress=res.item.adress
            this.queryForms.userdepname=res.item.userdepLists.userdepname
            this.queryForms.userdeppreson=res.item.userdeppreson
            this.queryForms.userdeppersonphone=res.item.userdeppersonphone
            this.queryForms.maintenancedepname=res.item.maintenancedepLists.userdepname
            this.queryForms.maintenanceperson1=res.item.maintenancedepLists.maintenanceperson1
            this.queryForms.maintenancephone1=res.item.maintenancedepLists.maintenancephone1
          })
        }
      }).catch(err => {
      })
    },
    close(){
      this.modalVisible = false
    },
  },
}
</script>

<style scoped>

</style>