<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="处理状态" prop="status">
              <a-select v-model="queryParams.status" style="width:140px">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">待处理</a-select-option>
                <a-select-option :value="1">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="接警人员" prop="called">
              <a-input v-model.trim="queryParams.called" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add">新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
          <span>{{value ? moment(value,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="alarmtype" slot-scope="value, record">
            <span :title="value">{{value="投诉"}}</span>
          </span>
          <span slot="status" slot-scope="value, record">
            <span :title="value">{{value==0?"待处理":"已完成"}}</span>
          </span>
          <span slot="detail" slot-scope="value, record">
            <a-button type="link" size="small" @click="detail(value,record)">详情</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="deleteData(value,record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-complaint :visible.sync="addComplaintVisible" ref="addComplaint"></add-complaint>
    <complaintBack :visible.sync="complaintVisible" ref="complaint" :ComplaintData="complaintData"></complaintBack>
  </page-container>
</template>

<script>
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {deleteAlarmessage, getAlarmessageListByCondition} from "A/thjlcx";
import complaintBack from "V/complaint/complaintBack";
import AddComplaint from "V/complaint/addComplaint";
import callCenter from "C/modals/callCenter";

export default {
  name: 'complaint',
  mixins: [areaselect, deptselect, pagination],
  props: ['pageType'],
  components: {
    AddComplaint,
    complaintBack
  },
  data() {
    return {
      moment,
      addComplaintVisible:false,
      complaintVisible:false,
      complaintData:'',
      showAdvanced: false,
      queryParams: {
        liftrescue: '',
        alarmtype:'',
        caller:'',
        callertel:'',
        called:'',
        calltime:'',
        message:'',
        status:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableColumns: [
        {
          title: '接警人员',
          dataIndex: 'called',
          key: 'called',
          ellipsis: true,
        },
        {
          title: '报警人员',
          dataIndex: 'caller',
          key: 'caller',
          ellipsis: true,
        },
        {
          title: '来电号码',
          dataIndex: 'callertel',
          key: 'callertel',
          ellipsis: true,
        },
        {
          title: '接警时间',
          dataIndex: 'calltime',
          key: 'calltime',
          ellipsis: true,
          scopedSlots: { customRender: 'time' }
        },
        {
          title: '报警信息',
          dataIndex: 'message',
          key: 'message',
          ellipsis: true,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '报警类型',
          dataIndex: 'alarmtype',
          key: 'alarmtype',
          ellipsis: true,
          scopedSlots: { customRender: 'alarmtype' }
        },
        {
          title: '处理状态',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '详情',
          key: 'detail',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'detail' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      counts:'',
    }
  },
  computed:{
    pageTitle() {
      return '投诉与建议历史';
    },
    breadcrumb() {
      return [
        {
          name: '投诉与建议',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getAlarmessageListByCondition(params).then(res => {
        this.tableLoading = false;
        var that = this;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
          const list = this.tableData.filter(item => item.alarmtype == 2 || item.alarmtype == 3)
          this.tableData = list
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    add(){
      this.addComplaintVisible=true;
    },
    detail(value,record){
      this.complaintData=record;
      this.complaintVisible=true;
    },
    deleteData(record,value){
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.id);
        },
        onCancel() {},
      });
    },
    delete(id) {
      deleteAlarmessage({id: id}).then(res => {
        if (res && res.returncode == '0') {
          this.getTableData(true)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>